<template>
  <div class="error-msg">
    <div class="error-msg-overlay">
      <p>{{ message.message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
    },
  },
};
</script>

<style scoped>
html,
body {
  padding: 0;
  margin: 0;
}

.error-msg {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/img/form-icons/geo.jpg");
  text-align: center;
  background-repeat: repeat;
  overflow: hidden;
}

.error-msg .error-msg-overlay {
  background-color: rgba(218, 236, 247, 0.9);
  height: 100%;
  padding: 30px 0px;
}

.error-msg .error-msg-overlay p {
  font-family: "PF Din Text Regular";
  font-size: 24px;
}
</style>
