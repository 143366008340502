import http from "./config";

const get = (endpoint) =>
  http
    .get(endpoint)
    .then((res) => res)
    .catch((err) => err);

const post = (endpoint, data) =>
  http
    .post(endpoint, data)
    .then((res) => res)
    .catch((err) => err);

export { get, post };
