<template>
  <section class="review-confirm">
    <div class="confirm-content">
      <header>
        <div class="header__bg"></div>
        <h1>Thank you for your feedback</h1>
        <h5>- we hope you enjoyed your stay with us -</h5>
      </header>
    </div>

    <div class="social-content">
      <div class="inner-div">
        <h2>Get Social</h2>
        <div class="social-icons">
          <ul>
            <li>
              <a href="https://www.facebook.com/stchostels">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/stchostels/">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/stchostels">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCDIJxYK5wh3UCp-vtbZI-uw">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M4.652 0h1.44l.988 3.702.916-3.702h1.454l-1.665 5.505v3.757h-1.431v-3.757l-1.702-5.505zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831v-3.349c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646v-3.179c0-.374.172-.651.529-.651.39 0 .557.269.557.651v3.179zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46v-5.238h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27v-6.831h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04c-.084.043-.167.109-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0-2.814.192-3.146 1.892-3.167 6.367.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zm-12.324 10.686h-1.363v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718c-.223.265-.455.467-.696.605-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="social-border">
      <div class="social__bg"></div>

      <h4>Get a discount on your next trip</h4>
      <p>
        <a href="https://www.st-christophers.co.uk/hostel-offers">
          take a look at our offers
          <i class="fas fa-arrow-right"></i>
        </a>
      </p>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: "review-form-confirmation",
};
</script>

<style>
.review-confirm {
  max-width: 1000px;
  margin: auto;
}
.review-confirm .confirm-content header {
  position: relative;
  height: 300px;
  overflow: hidden;
  z-index: 99999;
}
.review-confirm .confirm-content .header__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #75bcff;
  transform: skewY(4deg);
  transform-origin: top right;
}
.review-confirm .confirm-content h1,
.review-confirm .confirm-content h5 {
  margin: 0;
  text-align: center;
  position: relative;
}
.review-confirm .confirm-content h1 {
  padding: 40px 0px 20px 0px;
  font-family: "Zilla Slab", serif;
  font-size: 56px;
  font-weight: lighter;
  color: #ffffff;
}
.review-confirm .confirm-content h5 {
  font-family: "PF Din Text Regular";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 30px;
  color: #063242;
  margin: 0px;
}
.review-confirm .social-content {
  position: relative;
  background-image: url("https://bedsandbars.blob.core.windows.net/feedback-form/barca-bg-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -130px;
  height: 750px;
}
.review-confirm .social-content .inner-div {
  position: absolute;
  top: 35%;
  background: rgba(29, 57, 107, 0.8);
  width: 100%;
  text-align: center;
  padding: 30px 0px 60px 0px;
  color: #ffffff;
}
.review-confirm .social-content .inner-div h2 {
  font-family: "Open Sans";
  text-transform: uppercase;
  font-size: 55px;
  margin: 10px 0px;
}
.review-confirm .social-content .inner-div .social-icons {
  position: relative;
  text-align: center;
  padding: 0px;
}
.review-confirm .social-content .inner-div .social-icons ul {
  margin: 0px;
  padding: 0px;
}
.review-confirm .social-content .inner-div .social-icons ul li {
  list-style: none;
  display: inline-block;
  margin: 0px 20px;
  font-size: 40px;
}
.review-confirm .social-content .inner-div .social-icons ul li a {
  color: #ffffff;
  transition: 0.3s;
}
.review-confirm .social-content .inner-div .social-icons ul li a:hover {
  color: rgba(250, 250, 250, 0.7);
}
.review-confirm .social-border {
  position: relative;
  height: 250px;
  overflow: hidden;
  z-index: 99999;
  margin-top: -110px;
}
.review-confirm .social-border .social__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #75bcff;
  transform: skewY(4deg);
  transform-origin: bottom left;
}
.review-confirm .social-border h4,
.review-confirm .social-border p {
  position: relative;
  text-align: center;
  color: #ffffff;
}
.review-confirm .social-border h4 {
  font-family: "Zilla Slab", serif;
  font-size: 36px;
  font-weight: lighter;
  top: 30px;
}
.review-confirm .social-border p {
  top: 15px;
}
.review-confirm .social-border p a {
  background: #f68923;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans";
  transition: 0.3s;
  font-size: 16px;
}
.review-confirm .social-border p a i {
  margin-left: 5px;
}
.review-confirm .social-border p a:hover {
  background: #ec8524;
}
@media only screen and (max-width: 600px) {
  .review-confirm .confirm-content h1 {
    font-size: 34px;
  }
  .review-confirm .confirm-content h5 {
    font-size: 18px;
  }
  .review-confirm .social-border h4 {
    font-size: 30px;
  }
  .review-confirm .confirm-content header {
    height: 200px;
  }
}
@media only screen and (min-width: 600px) {
  .review-confirm {
    margin: 30px auto !important;
  }
}

/* FLYING PIG */

.review-confirm .flying-pig .confirm-content .header__bg,
.review-confirm .flying-pig .social-border .social__bg {
  background: #3dccb1;
}

.review-confirm .flying-pig .confirm-content h1 {
  font-family: "Quicksand";
  text-transform: uppercase;
  font-size: 50px;
  padding: 50px 0px 20px 0px;
}

.review-confirm .flying-pig .social-content .inner-div h2,
.review-confirm .flying-pig .social-border h4 {
  font-family: "Quicksand";
}

.review-confirm .flying-pig .confirm-content h5 {
  font-family: "Roboto";
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}

.review-confirm .flying-pig .social-content {
  background-image: url("https://bedsandbars.blob.core.windows.net/feedback-form/amst-bg.jpg");
}

.review-confirm .flying-pig .social-content .inner-div {
  /* background: rgba(61, 204, 177, 0.8); */
  background: rgba(246, 137, 34, 0.7);
}

@media only screen and (max-width: 600px) {
  .review-confirm .flying-pig .confirm-content h1 {
    font-size: 30px;
    padding: 20px 0px 20px 0px;
  }
  .review-confirm .flying-pig .confirm-content h5 {
    font-size: 20px;
  }
}
</style>
