import Vue from "vue";
import Router from "vue-router";
import ReviewForm from "./views/ReviewForm.vue";
import ReviewFormConfirmation from "./views/ReviewFormConfirmation.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "review-form",
      component: ReviewForm,
    },
    {
      path: "/confirmation",
      name: "review-form-confirmation",
      component: ReviewFormConfirmation,
    },
  ],
});
