<template>
  <section class="review-form">
    <error-message v-if="error" :message="error"></error-message>
    <div v-if="!error && !loading" class="background-overlay">
      <div :class="`page-content ${flyingPig()}`">
        <img class="location-icon" :src="image" alt />

        <h2 style="margin-bottom: 10px">
          Thanks for staying with us
          <span>{{ guest.first_name }}</span>
        </h2>

        <p>
          <span>We hope you enjoyed your visit from {{ arrivalDate }} - {{ departureDate }}</span>
        </p>

        <p>
          Please take a moment to fill in this short feedback form, it should take around 5 minutes.
        </p>

        <div id="feedback-form">
          <form @submit="submitForm" method="POST">
            <label class="label-heading"
              >Please let us know about your stay <br /><em
                style="font-family: 'PF Din Text Cond Pro'"
                >(will be visible on our website)</em
              ></label
            >
            <div class="form-dropdown">
              <textarea name="overallExperience" v-model="data.comment" required></textarea>
            </div>

            <div class="star-ratings">
              <label class="label-heading">How would you rate our value for money?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.value_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.value_rating && formSubmitted"
                >Please provide a rating</label
              >

              <label class="label-heading">How would you rate us on security?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.security_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.security_rating && formSubmitted"
                >Please provide a rating</label
              >

              <transition name="fade">
                <div
                  v-if="data.security_rating <= 3 && data.security_rating !== null"
                  id="security-dropdown"
                  class="form-dropdown"
                >
                  <label>How do you feel we can improve this?</label>
                  <textarea name="security-experience" v-model="data.security_comment"></textarea>
                </div>
              </transition>

              <label class="label-heading">How would you rate our staff?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.staff_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.staff_rating && formSubmitted"
                >Please provide a rating</label
              >

              <transition name="fade">
                <div
                  v-if="data.staff_rating <= 3 && data.staff_rating !== null"
                  id="security-dropdown"
                  class="form-dropdown"
                >
                  <label>How could we improve this?</label>
                  <textarea name="staff-experience" v-model="data.staff_comment"></textarea>
                </div>
              </transition>

              <div
                v-if="data.staff_rating === 5 && data.staff_rating !== null"
                id="security-dropdown"
                class="form-dropdown"
              >
                <label>Were there any staff members in particular?</label>
                <textarea name="staff-experience" v-model="data.staff_comment"></textarea>
              </div>

              <label class="label-heading">How would you rate us on fun?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.fun_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.fun_rating && formSubmitted"
                >Please provide a rating</label
              >

              <label class="label-heading">How would you rate our location?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.location_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.location_rating && formSubmitted"
                >Please provide a rating</label
              >

              <label class="label-heading">How would you rate us on cleanliness?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.cleanliness_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.cleanliness_rating && formSubmitted"
                >Please provide a rating</label
              >

              <transition name="fade">
                <div
                  v-if="data.cleanliness_rating <= 3 && data.cleanliness_rating !== null"
                  id="security-dropdown"
                  class="form-dropdown"
                >
                  <label>Tell us about your experience</label>
                  <div class="clean-icons">
                    <div class="pure-g">
                      <div class="pure-u-1-5">
                        <div class="clean-icon">
                          <img
                            src="https://bedsandbars.blob.core.windows.net/feedback-form/shower.svg"
                            alt
                          />
                          <h6>Shower</h6>
                          <input
                            type="checkbox"
                            v-model="data.cleanliness_icons"
                            name="Shower"
                            value="Shower"
                          />
                        </div>
                      </div>

                      <div class="pure-u-1-5">
                        <div class="clean-icon">
                          <img
                            src="https://bedsandbars.blob.core.windows.net/feedback-form/bed.svg"
                            alt
                          />
                          <h6>Bed</h6>
                          <input
                            type="checkbox"
                            v-model="data.cleanliness_icons"
                            name="Bed"
                            value="Bed"
                          />
                        </div>
                      </div>

                      <div class="pure-u-1-5">
                        <div class="clean-icon">
                          <img
                            src="https://bedsandbars.blob.core.windows.net/feedback-form/room.svg"
                            alt
                          />
                          <h6>Room</h6>
                          <input
                            type="checkbox"
                            v-model="data.cleanliness_icons"
                            name="Room"
                            value="Room"
                          />
                        </div>
                      </div>

                      <div class="pure-u-1-5">
                        <div class="clean-icon">
                          <img
                            src="https://bedsandbars.blob.core.windows.net/feedback-form/toilet.svg"
                            alt
                          />
                          <h6>Toilet</h6>
                          <input
                            type="checkbox"
                            v-model="data.cleanliness_icons"
                            name="Toilet"
                            value="Toilet"
                          />
                        </div>
                      </div>

                      <div class="pure-u-1-5">
                        <div class="clean-icon">
                          <img
                            src="https://bedsandbars.blob.core.windows.net/feedback-form/common-areas.svg"
                            alt
                          />
                          <h6>Hostel</h6>
                          <input
                            type="checkbox"
                            v-model="data.cleanliness_icons"
                            name="Common Area"
                            value="Common Area"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <textarea
                    name="clean-experience"
                    v-model="data.cleanliness_comment"
                    :required="data.cleanliness_rating <= 3 && data.cleanliness_rating !== null"
                  ></textarea>
                </div>
              </transition>

              <label class="label-heading">How would you rate us on facilities?</label>
              <star-rating
                :showRating="false"
                :star-size="30"
                v-model="data.facilities_rating"
              ></star-rating>
              <label class="label-error" v-if="!data.facilities_rating && formSubmitted"
                >Please provide a rating</label
              >

              <transition name="fade">
                <div
                  v-if="data.facilities_rating <= 3 && data.facilities_rating !== null"
                  id="facilities-dropdown"
                  class="form-dropdown"
                >
                  <label>How do you feel we can improve this?</label>
                  <textarea
                    name="facilities-experience"
                    v-model="data.facilities_comment"
                  ></textarea>
                </div>
              </transition>
            </div>

            <label class="label-heading">Why did you choose to stay with us?</label>

            <div class="reason-for-stay">
              <div v-if="!guest.is_direct">
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Location"
                  name="Location"
                  value="Location"
                />
                Location<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Price"
                  name="Price"
                  value="Price"
                />
                Price<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Friends Recommendation"
                  name="Friends Recommendation"
                  value="Friends Recommendation"
                />
                Friend’s Recommendation<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Reviews"
                  name="Reviews"
                  value="Reviews"
                />
                Reviews<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Previous Experience with Us"
                  name="Previous Experience with Us"
                  value="Previous Experience with Us"
                />
                Previous Experience with Us<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="The Bar"
                  name="The Bar"
                  value="The Bar"
                />
                The Bar<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Green Initiatives"
                  name="Green Initiatives"
                  value="Green Initiatives"
                />
                Green Initiatives<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="Safety"
                  name="Safety"
                  value="Safety"
                />
                Safety<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  id="N/A"
                  name="N/A"
                  value="N/A"
                />
                N/A<br />
              </div>
              <div v-else>
                <input v-model="data.reason_for_stay" type="checkbox" class="input" value="Price" />
                Price<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  value="Convenience"
                />
                Convenience<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  value="Free Breakfast"
                />
                Free Breakfast<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  value="No deposit"
                />
                No deposit<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  value="Free cancellation"
                />
                Free cancellation<br />
                <input
                  v-model="data.reason_for_stay"
                  type="checkbox"
                  class="input"
                  value="Our Ts & Cs"
                />
                Our Ts & Cs<br />
              </div>

              <input v-model="data.reason_for_stay" type="checkbox" class="input" value="Other" />
              Other<br />

              <textarea
                style="margin-top: 5px"
                name="other"
                placeholder="Please type your reasons here"
                v-if="data.reason_for_stay.includes('Other')"
                v-model="otherReason"
              ></textarea>
            </div>

            <h4 class="label-heading">Where to next?</h4>

            <div class="where-next">
              <div class="pure-g">
                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/amsterdam-2.png"
                      alt
                    />
                    <h6>Amsterdam</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Amsterdam"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/barcelona-3.png"
                      alt
                    />
                    <h6>Barcelona</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Barcelona"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/berlin-icon.png"
                      alt
                    />
                    <h6>Berlin</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Berlin"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/bruges-3.png"
                      alt
                    />
                    <h6>Bruges</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Bruges"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/Copenhagen.png"
                      alt
                    />
                    <h6>Copenhagen</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Copenhagen"
                      checked
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/paris-et.png"
                      alt
                    />
                    <h6>Paris</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Paris"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/prague-icon.png"
                      alt
                    />
                    <h6>Prague</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Prague"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/bath-icon.png"
                      alt
                    />
                    <h6>Bath</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Bath"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/cardiff-icon.png"
                      alt
                    />
                    <h6>Cardiff</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Cardiff"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/edinburgh-icon.png"
                      alt
                    />
                    <h6>Edinburgh</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Edinburgh"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/london-icon.png"
                      alt
                    />
                    <h6>London</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="London"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/newquay-icon.png"
                      alt
                    />
                    <h6>Newquay</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Newquay"
                    />
                  </div>
                </div>

                <div class="pure-u-1-3 pure-u-md-1-5 pure-u-lg-1-3">
                  <div class="location-tile">
                    <img
                      src="https://bedsandbars.blob.core.windows.net/feedback-form/Airport.png"
                      alt
                    />
                    <h6>Other</h6>
                    <input
                      type="radio"
                      name="where_to_next"
                      v-model="data.where_next"
                      value="Other"
                    />
                  </div>
                </div>
              </div>
            </div>

            <label for="anonymous" class="anonymise">Make your feedback anonymous?</label>
            <input
              type="checkbox"
              name="anonymous"
              id="anonymous"
              value="true"
              v-model="data.anonymised_at"
              checked
            />

            <br />

            <label for="staff_contact_opt_in_at" class="anonymise"
              >I am open to a member of staff emailing me to follow up on my feedback</label
            >
            <input
              type="checkbox"
              name="staff_contact_opt_in_at"
              id="staff_contact_opt_in_at"
              value="true"
              v-model="data.staff_contact_opt_in_at"
              checked
            />

            <label
              class="label-error"
              v-if="Object.values(ratings).includes(null) && formSubmitted"
            >
              Form not submitted - please provide a star rating for each factor.
            </label>

            <br />
            <input class="submit-btn" type="submit" name="form-submit" value="Submit Feedback" />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import dateFormat from "dateformat";
import StarRating from "vue-star-rating";
import ErrorMessage from "./ErrorMessage.vue";
import { checkToken, submitReview } from "../api/reviewService";

export default {
  name: "review-form",
  components: {
    StarRating,
    ErrorMessage,
  },
  data() {
    return {
      otherReason: "",
      loading: true,
      guest: {},
      locationImage: [
        {
          hostels: ["HMM", "SHP", "CMD", "GRE", "VIL", "INN", "OAS", "LPS"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/london-icon.png",
        },
        {
          hostels: ["WIN", "FPU", "FPD"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/amsterdam-2.png",
        },
        {
          hostels: ["BCN"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/barcelona-3.png",
        },
        {
          hostels: ["PAR", "GDN"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/paris-et.png",
        },
        {
          hostels: ["BTH"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/bath-icon.png",
        },
        {
          hostels: ["EDO", "EDI"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/edinburgh-icon.png",
        },
        {
          hostels: ["NQY"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/newquay-icon.png",
        },
        {
          hostels: ["BER", "BRA", "BRM"],
          images: "https://bedsandbars.blob.core.windows.net/feedback-form/berlin-icon.png",
        },
      ],
      error: null,
      formSubmitted: false,
      data: {
        token: this.$route.query.token,
        comment: null,
        value_rating: null,
        security_rating: null,
        security_comment: null,
        staff_rating: null,
        staff_comment: null,
        fun_rating: null,
        fun_comment: null,
        location_rating: null,
        cleanliness_rating: null,
        cleanliness_comment: null,
        cleanliness_icons: [],
        where_next: null,
        anonymised_at: null,
        staff_contact_opt_in_at: true,
        facilities_rating: null,
        facilities_comment: null,
        reason_for_stay: [],
      },
    };
  },
  async created() {
    if (!this.data.token) {
      this.error = { message: "No Token Submitted" };
      return null;
    }

    const data = await checkToken(this.data.token).then((res) => {
      const status = res.response || null;

      if (res.status !== 200) {
        this.error = { message: "Already submitted review" };
        return;
      }

      return res.data;
    });

    this.guest = data;

    if (["FPU", "FPD"].includes(this.guest.hostel_code)) {
      document.querySelector("link[rel='icon']").href = "/fp-fav.png";
      document.title = "Review your stay at The Flying Pig";
    }

    this.loading = false;
  },
  computed: {
    arrivalDate() {
      return dateFormat(new Date(this.guest.arrival_date), "dS mmm");
      // return "01-01-20";
    },
    departureDate() {
      return dateFormat(new Date(this.guest.depatured_date), "dS mmm, yyyy");
      // return "03-01-20";
    },
    hostel() {
      return this.guest;
    },
    image() {
      const imageObj = this.locationImage.filter((obj) =>
        obj.hostels.includes(this.guest.hostel_code)
      );

      return imageObj[0] ? imageObj[0].images : "";
    },
    ratings() {
      const {
        value_rating,
        cleanliness_rating,
        staff_rating,
        facilities_rating,
        security_rating,
        fun_rating,
        location_rating,
      } = this.data;
      return {
        value_rating,
        cleanliness_rating,
        staff_rating,
        facilities_rating,
        security_rating,
        fun_rating,
        location_rating,
      };
    },
  },
  methods: {
    updateOtherReason(e) {
      console.log(e);
    },
    flyingPig() {
      return ["FPU", "FPD"].includes(this.guest.hostel_code) ? "flying-pig" : "";
    },
    submitForm(e) {
      e.preventDefault();
      this.formSubmitted = true;

      if (this.data.reason_for_stay.includes("Other") && this.otherReason !== "") {
        this.data.reason_for_stay.push(this.otherReason);
      }

      submitReview({ ...this.data, ...this.guest }).then((res) => {
        const status = res.response || null;

        if (status) {
          // TODO? display errors to user
          console.log(res.response.data);
        } else {
          // no errors
          this.$router.push("/confirmation");
        }
      });
    },
  },
};
</script>

<style>
.review-form {
  background-image: url("https://bedsandbars.blob.core.windows.net/feedback-form/geo.jpg");
  background-repeat: repeat;
}
.review-form h2 {
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 24px;
  margin-bottom: 30px;
}
.review-form h4 {
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 22px;
  margin-bottom: 20px;
}
.review-form p {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 16px;
  color: #3b444f;
  line-height: 1.69;
}
.review-form p span {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 16px;
  color: #f68923;
  line-height: 1.69;
  font-weight: lighter;
}
.review-form .background-overlay {
  background-color: rgba(218, 236, 247, 0.9);
  margin: 0px;
  text-align: center;
  padding: 10px;
}
.review-form .background-overlay .page-content {
  max-width: 750px;
  margin: auto;
}
.review-form .background-overlay .page-content .location-icon {
  margin: 30px 0px 0px;
  max-height: 100px;
}
.review-form .background-overlay .page-content .form-btn {
  margin: 40px 0px;
}
.review-form .background-overlay .page-content .form-btn a {
  padding: 10px 20px;
  background: #f68923;
  border-radius: 4px;
  color: #ffffff;
  text-decoration: none;
  font-family: "PF Din Text Regular";
}
.review-form .background-overlay .page-content .form-btn a:hover {
  background: #ea7c14;
}
.review-form .background-overlay .page-content form label {
  display: block;
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 20px;
  margin: 30px 0px 15px 0px;
}
.review-form .background-overlay .page-content form .label-heading {
  margin: 50px auto 20px;
}
.review-form .background-overlay .page-content form textarea {
  min-width: 85%;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  /* margin: 5px; */
  border-radius: 4px;
  border: none !important;
  color: #3b444f;
  font-family: "Open Sans";
  font-size: 16px;
}
.review-form .background-overlay .page-content form .star-ratings {
  margin: 40px 0px;
}
.review-form .background-overlay .page-content form .star-ratings .checked {
  color: #f68923;
}
.review-form .background-overlay .page-content form .form-dropdown label {
  font-size: 16px;
}
.review-form .background-overlay .page-content form .form-dropdown .clean-icons {
  max-width: 660px;
  margin: auto;
  margin-bottom: 10px;
}
.review-form .background-overlay .page-content form .form-dropdown .clean-icons .clean-icon {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px 5px;
  margin: 2px;
  border-radius: 4px;
}
.review-form .background-overlay .page-content form .form-dropdown .clean-icons .clean-icon h6 {
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 12px;
  margin: 10px 0px;
}
.review-form .background-overlay .page-content form .form-dropdown .clean-icons .clean-icon img {
  max-height: 30px;
  width: auto;
}
.review-form .background-overlay .page-content form .form-dropdown .clean-icons textarea {
  margin-top: 5px;
}
.review-form .background-overlay .page-content form .where-next {
  max-width: 660px;
  margin: auto;
}
.review-form .background-overlay .page-content form .location-tile {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  margin: 3px;
  border-radius: 4px;
  border: none;
}
.review-form .background-overlay .page-content form .location-tile h6 {
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  font-size: 12px;
  color: #3b444f;
  margin: 10px 0px;
}
.review-form .background-overlay .page-content form .location-tile img {
  max-height: 60px;
  width: auto;
  margin-top: 10px;
}
.review-form .background-overlay .page-content form .anonymise {
  display: inline-block;
  margin-right: 10px;
}
.review-form .background-overlay .page-content form .submit-btn {
  border: 1px solid #3b444f;
  padding: 10px 20px;
  background: none;
  margin: 20px 0px;
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  margin: 30px 0px 40px 0px;
  color: #3b444f;
  transition: 0.6s;
  font-size: 18px;
}
.review-form .background-overlay .page-content form .submit-btn:hover {
  background: #3b444f;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .review-form .background-overlay .page-content form .location-tile h6,
  .review-form .background-overlay .page-content form .form-dropdown .clean-icons .clean-icon h6 {
    font-size: 16px !important;
  }
  .review-form h3 {
    font-size: 44px !important;
    margin-bottom: 30px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.vue-star-rating {
  margin: auto;
}

/* FLYING PIG */
.review-form .flying-pig h2 {
  color: #393d41;
  font-size: 30px;
  font-family: "Quicksand";
  text-transform: uppercase;
}

.review-form .flying-pig p span {
  color: #ff851f;
  text-transform: uppercase;
  font-size: 18px;
}

.review-form .flying-pig p,
.review-form .background-overlay .flying-pig form textarea,
.review-form .background-overlay .flying-pig form .form-dropdown label,
.review-form .background-overlay .flying-pig form .form-dropdown .clean-icons .clean-icon h6,
.review-form .background-overlay .flying-pig form .location-tile h6 {
  font-family: "Roboto";
  color: #393d41;
}

.review-form .background-overlay .flying-pig form .label-heading,
.review-form .background-overlay .flying-pig form label {
  color: #393d41;
  font-family: "Quicksand";
  font-size: 18px;
}

.review-form .background-overlay .flying-pig form .submit-btn {
  background: #ff851f;
  color: #fff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 40px;
}

.review-form .background-overlay .flying-pig form .submit-btn:hover {
  background: #e67619;
}

.review-form .background-overlay .flying-pig form textarea {
  background: rgba(243, 255, 253, 0.5);
}

.reason-for-stay {
  width: 250px;
  margin: auto;
  font-family: "PF Din Text Cond Pro Med";
  font-weight: lighter;
  color: #3b444f;
  font-size: 18px;
  text-align: left;
}

.reason-for-stay .input {
  float: right;
}
.review-form .background-overlay .page-content form label.label-error {
  color: orangered;
}
</style>
